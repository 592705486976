import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Navigation from "components/Navigation";
import ROUTES from "routes";
import { Login } from "pages";

const App = () => {
  return (
    <BrowserRouter>
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Navigate to="/organisations" replace />} />
          <Route element={<Navigation />}>
            {ROUTES.filter((route) => route.path !== "/").map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Route>
          <Route path="*" element={<Navigate to="/organisations" replace />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </UnauthenticatedTemplate>
    </BrowserRouter>
  );
};

export default App;
