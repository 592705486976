import {FC, useState} from "react";
import { Box, IconButton } from "@mui/material";
import { Preview, DownloadForOffline } from "@mui/icons-material";
import { Connection } from "types";
import SyncConnectionsDataDialog from "../Dialogs/SyncConnectionsDataDialog";

interface Props {
  clientId: string;
  connection: Connection;
  handleOpen: () => void;
}

const FortnoxButton: FC<Props> = ({ clientId, connection, handleOpen }) => {
  const [fetchDialogOpen, setFetchDialogOpen] = useState(false);

  const handleFetchOpen = () => {
    console.log("open")
    if (!fetchDialogOpen) {
      setFetchDialogOpen(true);
    }
  }

  const handleFetchClose = () => {
    console.log("close")
    setFetchDialogOpen(false);
  }
  
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleOpen();
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <Preview />
      </IconButton>
      <IconButton onClick={handleFetchOpen}>
        <DownloadForOffline />
        {fetchDialogOpen && clientId && (
          <SyncConnectionsDataDialog clientId={clientId} open={fetchDialogOpen} handleClose={handleFetchClose} connection={connection}></SyncConnectionsDataDialog>
        )}
      </IconButton>
    </Box>
  );
};

export default FortnoxButton;
