export interface LoginFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface OrganisationFormValues {
  name: string;
}

export interface ClientFormValues {
  id?: number;
  name: string;
  email: string;
  organisationId: string;
}

export interface Organisation {
  id: string;
  name: string;
  clientNr: number;
  createdAt: string;
}

export interface Client {
  id: string;
  name: string;
  email: string;
  joinedAt: string;
  organisationId: string;
  connections: Connection[];
  resourcesInfo: ResourcesInfo | undefined;
}

export interface ResourcesInfo {
  workspaceId: string;
  workspaceName: string;
  pipelineId: string;
}

export enum ObjectType {
  ORGANISATION = "organisation",
  CLIENT = "client",
  CONNECTION = "connection",
}

export enum DialogMode {
  VIEW = "view",
  CREATE = "create",
}

export enum ActionType {
  OPEN = "open",
  FETCH = "fetch",
}

export enum SyncType {
  FULL = "full",
  DELTA = "delta"
}

export enum ComponentColorMode {
  LIGHT = "light",
  DARK = "dark",
}

export interface Organisation {
  id: string;
  name: string;
  clientNr: number;
}

export interface OrganisationFormValues {
  name: string;
}

export interface FortnoxActivationPayload {
  clientId: string;
  scopes: string[];
}

export interface FortnoxActivationResponse {
  activationUrl: string;
}

export enum ConnectionType {
  Fortnox = 1,
}

export enum ConnectionStatus {
  OK = 1,
  Failed = 2,
}

export interface Connection {
  id: string;
  type: ConnectionType;
  status: ConnectionStatus;
  triggeredTimestampUtc: string;
  metadata?: { [key: string]: string };
  clientId: string;
}

export interface JobStatus {
  id: string;
  clientId: string;
  clientName: string;
  connectionIds: string[];
  connectionType: string;
  jobType: string;
  status: string;
  stepPercentage: number;
  totalSteps: number;
  completedSteps: number;
  started: string;
  updated: string;
  syncType: string;
  hasError: boolean;
  errorMessage: string;
}
