import { FC, useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";

import { Trans, useTranslation } from "react-i18next";

import { Connection, DialogMode, FortnoxCompanyInformation } from "types";
import {
  fetchFortnoxActivationUrl,
  fetchFortnoxScopes,
} from "api/callbacks/fortnox";

type Props = {
  mode: DialogMode;
  clientId: string;
  connection?: Connection;
};

const FortnoxConnection: FC<Props> = ({ mode, connection, clientId }) => {
  const { t } = useTranslation();
  const [scopes, setScopes] = useState<string[]>([]);
  const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
  const [companyInformation, setCompanyInformation] = useState<
    FortnoxCompanyInformation | undefined
  >();
  const [error, setError] = useState<string | undefined>();
  const [activationUrl, setActivationUrl] = useState<string | undefined>();
  const [copied, setCopied] = useState<boolean>(false);

  const loadFortnoxScopes = useCallback(async () => {
    try {
      const { data: fetchedScopes } = await fetchFortnoxScopes();
      setScopes(fetchedScopes);

      if (mode === DialogMode.CREATE) {
        setSelectedScopes(fetchedScopes);
      }
      
    } catch (error) {
      setError(t("alerts:fortnox_scope_error"));
      console.error("Error fetching Fortnox scopes:", error);
    }
  }, [t, mode]);
  
  useEffect(() => {
    loadFortnoxScopes();

    if (mode === DialogMode.VIEW && connection) {
      setSelectedScopes(connection.metadata?.scope?.split(" ") || []);
      if (connection?.metadata?.companyInformation) {
        const companyInfo: FortnoxCompanyInformation = JSON.parse(
          connection.metadata.companyInformation
        );
        setCompanyInformation(companyInfo);
      }
    }
  }, [mode, connection, loadFortnoxScopes]);

  const handleScopeChange = (scope: string) => {
    setSelectedScopes((prevScopes) =>
      prevScopes.includes(scope)
        ? prevScopes.filter((s) => s !== scope)
        : [...prevScopes, scope]
    );
  };

  const onFortnoxIntegrationClick = async () => {
    try {
      const { data } = await fetchFortnoxActivationUrl({
        clientId: clientId,
        scopes: selectedScopes,
      });

      const { activationUrl } = data;
      setActivationUrl(activationUrl);
    } catch (error) {
      setError(t("alerts:fortnox_integration_error"));
      console.error("Error during Fortnox integration:", error);
    }
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t("fortnox_integration_with", { clientName: clientId })}
      </Typography>

      {error ? (
        <Typography color="error">{t(error)}</Typography>
      ) : (
        <Stack spacing={2}>
          {companyInformation && (
            <Box>
              <Typography variant="body2">
                <Trans
                  i18nKey="company_name_with_value"
                  values={{ companyName: companyInformation.CompanyName }}
                  components={{ strong: <strong /> }}
                />
              </Typography>
              <Typography variant="body2">
                <Trans
                  i18nKey="organisation_number_with_value"
                  values={{ orgNr: companyInformation.OrganizationNumber }}
                  components={{ strong: <strong /> }}
                />
              </Typography>
            </Box>
          )}

          <Typography variant="subtitle1">
            {mode === DialogMode.VIEW
              ? t("selected_scopes")
              : t("available_scopes")}
          </Typography>

          <Grid container spacing={2}>
            {scopes.map((scope) => (
              <Grid size={{ xs: 4 }} key={scope}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={mode === DialogMode.VIEW || !!activationUrl}
                      checked={selectedScopes.includes(scope)}
                      onChange={() => handleScopeChange(scope)}
                    />
                  }
                  label={t(`scopes:${scope}`)}
                />
              </Grid>
            ))}
          </Grid>

          {mode === DialogMode.CREATE && (
            <Button
              disabled={!selectedScopes.length || !!activationUrl}
              variant="contained"
              color="primary"
              onClick={onFortnoxIntegrationClick}
            >
              {t("generate_activation_url")}
            </Button>
          )}

          {mode === DialogMode.CREATE && !!activationUrl && (
            <div
              style={{
                position: "relative",
                display: "inline-block",
                width: "100%",
              }}
            >
              <TextField
                id="outlined-multiline-static"
                label="Activation Url"
                multiline
                rows={4}
                defaultValue={activationUrl}
                disabled={true}
                fullWidth
              />
              <Button
                onClick={() => {
                  if (copied) return;
                  navigator.clipboard.writeText(activationUrl);
                  setCopied(true);
                  setTimeout(() => setCopied(false), 2000);
                }}
                color={copied ? "success" : "primary"}
                variant="contained"
                style={{
                  position: "absolute",
                  bottom: 8,
                  right: 8,
                }}
              >
                {!copied && <ContentCopy />}
                {copied && t("copied")}
              </Button>
            </div>
          )}
        </Stack>
      )}
    </>
  );
};

export default FortnoxConnection;
