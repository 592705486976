import React, {FC, useState} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Connection, SyncType} from "../../types";
import {fortnoxFetchAll, fortnoxFetchSingle} from "../../api/callbacks";
import {useSnackbar} from "notistack";

type Props = {
  clientId: string;
  open: boolean;
  handleClose: (reload: boolean) => void;
  connection?: Connection;
};

const SyncConnectionsDataDialog: FC<Props> = ({open, handleClose, connection, clientId}) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [syncType, setSyncType] = useState<SyncType>(SyncType.DELTA);

  const onClose = () => {
    handleClose(false);
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const started = !!connection
        ? await fortnoxFetchSingle(clientId, connection.id, syncType)
        : await fortnoxFetchAll(clientId, syncType);

      if (started) {
        enqueueSnackbar(t("fortnox_fetch_started"), {variant: "success"});
      } else {
        enqueueSnackbar(t("fortnox_fetch_failed"), {variant: "error"});
      }
      handleClose(true);
    } catch (error) {
      console.error("Sync failed", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSyncType(event.target.value as SyncType);
  };

  return (
    <Dialog {...{open, onClose}}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: 4,
          boxShadow: 24,
          borderRadius: 1,
          width: 400,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {t("sync_data")}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t("fast_or_from_scratch")}
        </Typography>

        <form onSubmit={onSubmitHandler}>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Radio
                  checked={syncType === SyncType.DELTA}
                  onChange={handleRadioChange}
                  value={SyncType.DELTA}
                  name="sync-type"
                  inputProps={{'aria-label': 'Delta Sync'}}
                />
              }
              label={t("radio_delta")}
            />

            <FormControlLabel
              control={
                <Radio
                  checked={syncType === SyncType.FULL}
                  onChange={handleRadioChange}
                  value={SyncType.FULL}
                  name="sync-type"
                  inputProps={{'aria-label': 'Full Sync'}}
                />
              }
              label={t("radio_full")}
            />
          </FormControl>

          <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
            {isLoading && <CircularProgress color="primary"/>}
            <Button onClick={onClose} color="inherit" disabled={isLoading}>
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? t("sync_loading") : t("sync")}
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default SyncConnectionsDataDialog;
