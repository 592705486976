import { FC } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Connection, ConnectionType } from "types";
import FortnoxButton from "./FortnoxButton";

type Props = {
  clientId: string;
  connection: Connection;
  handleOpen: () => void;
};

const ConnectionHandler: FC<Props> = ({ clientId, connection, handleOpen }) => {
  const { t } = useTranslation();

  switch (connection.type) {
    case ConnectionType.Fortnox:
      return <FortnoxButton {...{ clientId, connection, handleOpen }} />;
    default:
      return <Box>{t("something_went_wrong")}</Box>;
  }
};

export default ConnectionHandler;
