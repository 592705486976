import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  DialogProps,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DialogMode, Organisation, OrganisationFormValues } from "types";
import { createOrganisation, updateOrganisation } from "api/callbacks";
import ErrorAlert from "../ErrorAlert";

type Props = {
  open: boolean;
  handleClose: (reload: boolean) => void;
  initialData?: Organisation;
  mode: DialogMode;
};

const OrganisationModal: FC<Props> = ({
  open,
  handleClose,
  initialData,
  mode,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { handleSubmit, control } = useForm<OrganisationFormValues>({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    control._reset({
      name: initialData?.name ?? "",
    });
  }, [mode, initialData, control]);

  const onSubmitHandler = async (org: OrganisationFormValues) => {
    if (org.name === "") return;
    setIsLoading(true);
    setError("");
    try {
      if (mode === DialogMode.CREATE) {
        await createOrganisation(org);
      } else if (mode === DialogMode.VIEW && initialData?.id) {
        await updateOrganisation(initialData?.id, org);
      }
      setIsLoading(false);
      handleClose(true);
    } catch (error) {
      console.error("Error creating or updating organisation:", error);
      setIsLoading(false);
      setError("" + error);
    }
  };

  const onClose: DialogProps["onClose"] = (_event, reason) => {
    if (isLoading && reason && reason === "backdropClick") return;
    handleClose(false);
  };

  return (
    <Modal {...{ open, onClose }}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: 4,
          boxShadow: 24,
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {mode === "create" ? t("add_new_org") : t("edit_org")}
        </Typography>
        <Stack spacing={2}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t("alerts:organisation_name_required"),
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("organisation_name")}
                variant="outlined"
                fullWidth
                error={!!error}
                helperText={error ? error.message : ""}
                disabled={isLoading || !!error}
              />
            )}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading || !!error}
          >
            {mode === "create" ? t("add") : t("save_changes")}
          </Button>
        </Stack>
        <Box
          mt={"10px"}
          display={"flex"}
          justifyContent="center"
          alignItems="center"
        >
          {isLoading && <CircularProgress color="primary" />}
          {!!error && <ErrorAlert title="Error" message={error} />}
        </Box>
      </Box>
    </Modal>
  );
};

export default OrganisationModal;
