import { axiosInstance } from "utils";
import { FortnoxActivationPayload, FortnoxActivationResponse } from "types";

export const fetchFortnoxScopes = async () => {
  return await axiosInstance.get<string[]>("/connection/fortnox/scopes");
};

export const fetchFortnoxActivationUrl = async ({
  clientId,
  scopes,
}: FortnoxActivationPayload) => {
  const params = new URLSearchParams({
    clientId,
  });

  scopes.forEach((scope) => params.append("scope", scope));

  return await axiosInstance.get<FortnoxActivationResponse>(
    `/connection/fortnox/activation/url?${params.toString()}`
  );
};
