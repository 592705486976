import { FC } from "react";

import ApartmentIcon from "@mui/icons-material/Apartment";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  Login,
  Organisations,
  OrganisationClients,
  OrganisationClientConnections,
  Settings
} from "pages";

export interface Route {
  path: string;
  Component: FC;
  onNav: boolean;
  bottomStack?: boolean
  title?: string;
  Icon?: FC;
}

const ROUTES: Route[] = [
  {
    path: "/",
    Component: Login,
    onNav: false,
  },
  {
    path: "/organisations",
    Component: Organisations,
    onNav: true,
    title: "organisations",
    Icon: ApartmentIcon,
  },
  {
    path: "/organisations/:id",
    Component: OrganisationClients,
    onNav: false,
  },
  {
    path: "/organisations/:id/client/:clientId",
    Component: OrganisationClientConnections,
    onNav: false,
  },
  {
    path: "/settings",
    Component: Settings,
    onNav: true,
    bottomStack: true,
    title: "settings_title",
    Icon: SettingsIcon,
  },
];

export default ROUTES;
