import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, IconButton,
  Tooltip,
} from "@mui/material";
import { DeleteForever as DeleteIcon} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { ObjectType } from "types";

interface DeleteButtonProps {
  onDelete: () => void;
  itemName: string;
  itemType: ObjectType;
  disabled?: boolean;
  disabledReason?: string;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onDelete,
  itemName,
  itemType,
  disabled = false,
  disabledReason,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDialogOpen(true);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDialogOpen(false);
  };

  const handleConfirm = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete();
    setDialogOpen(false);
  };

  return (
    <Tooltip title={disabled && disabledReason ? disabledReason : ""}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          bgcolor: "background.paper",
          color: "text.secondary",
          cursor: "pointer",
          width: "fit-content",
          "& svg": {
            m: 2,
          },
        }}
      >
        <IconButton
            disabled={disabled}
            onClick={handleClick}
            sx={{ m: 0, p:0, "&:hover": { color: "warning.main" } }}
            style={{borderRadius: 0}}
        >
          <DeleteIcon/>
        </IconButton>
        
        <Dialog
          open={dialogOpen && !disabled}
          onClose={handleClose}
          aria-labelledby="delete-confirmation-dialog-title"
          aria-describedby="delete-confirmation-dialog-description"
        >
          <DialogTitle id="delete-confirmation-dialog-title" fontSize={25}>
            {t("confirm_deletion_title", {type: t(itemType) })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-confirmation-dialog-description">
              {t("confirm_deletion", { itemName, type: t(itemType) })}
            </DialogContentText>
            <DialogContentText id="delete-confirmation-dialog-warning" fontWeight="bold" color="text">
              {t("cant_undo")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} color="inherit" sx={{fontWeight: "bold", fontSize: "small"}}>
              {t("cancel")}
            </Button>
            <Button variant="contained" onClick={handleConfirm} color="warning" sx={{fontWeight: "bold", fontSize: "small"}}>
              {t("delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Tooltip>
  );
};

export default DeleteButton;
