import { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Toolbar,
} from "@mui/material";
import { ArrowBack, Logout } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import * as signalR from "@microsoft/signalr";

import ROUTES from "routes";
import sevrBlackLogo from "assets/images/sevr-black.png";
import LanguageSelector from "components/LanguageSelector";
import Notifications from "components/Notifications";
import { fetchJobStatuses } from "api/callbacks";
import { JobStatus } from "types";
import { getAccessToken } from "auth";
import { Route } from "routes/routes";

const BACKBUTTON_BLACKLIST = ["/organisations", "/settings"];

const Navigation: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { instance, inProgress } = useMsal();

  const onLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });
  };

  const [jobStatuses, setJobStatuses] = useState<JobStatus[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: jobs } = await fetchJobStatuses();
      setJobStatuses(jobs);
    };
    fetchData();

    const initialise = async () => {
      const token = await getAccessToken(instance, inProgress, [
        process.env.REACT_APP_API_SCOPE!,
      ]);
      if (token) {
        const hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_SIGNALR_HOST}/jobstatus`, {
            accessTokenFactory: () => token,
          })
          .withAutomaticReconnect()
          .configureLogging(signalR.LogLevel.Information)
          .build();

        if (!hubConnection) {
          throw new Error("Failed to create SignalR connection");
        }

        hubConnection
          .start()
          .then(() => {
            hubConnection.on("JobStatusUpdate", (message) => {
              const jobStatus = message;
              setJobStatuses((current) => {
                const index = current.findIndex((js) => js.id === jobStatus.id);
                if (index > -1) {
                  const updatedStatuses = [...current];
                  updatedStatuses[index] = jobStatus;
                  return updatedStatuses;
                } else {
                  return [jobStatus, ...current];
                }
              });
            });
          })
          .catch((err) => console.error("SignalR Connection failed: ", err));
        return () => {
          hubConnection && hubConnection.stop();
        };
      }
    };
    initialise();
  }, [inProgress, instance]);

  const showBackButton = !BACKBUTTON_BLACKLIST.includes(pathname);

  const MenuListItem = ({ path, Icon, title }: Route) => (
    <ListItem disablePadding key={path}>
      <ListItemButton onClick={() => navigate(path)}>
        {Icon && (
          <ListItemIcon sx={{ ml: 2 }}>
            <SvgIcon
              color={pathname === path ? "primary" : "secondary"}
              sx={{ fontSize: 31 }}
            >
              <Icon />
            </SvgIcon>
          </ListItemIcon>
        )}
        {title && (
          <ListItemText
            primary={t(title)}
            primaryTypographyProps={{
              sx: {
                fontWeight: "bold",
                whiteSpace: "nowrap",
                color:
                  pathname === path
                    ? "primary.main"
                    : "secondary.main",
              },
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  )

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={{ width: 240, flexShrink: 0 }}
        PaperProps={{
          style: { width: 240, backgroundColor: "#0a0e16", border: 0 },
        }}
      >
        <Box sx={{ textAlign: "center", my: 2 }}>
          <img
            src={sevrBlackLogo}
            alt="Sevr Logo"
            style={{ width: "80%", height: "auto" }}
          />
        </Box>
        <Divider />
        <Stack spacing={2} mt={2}>
          {ROUTES.filter((route) => route.onNav && !route.bottomStack).map(MenuListItem)}
        </Stack>
        <Stack 
          sx={{
              padding: 3,
              mt: "auto",
              fontWeight: "bold",
              
            }}>
          {ROUTES.filter((route) => route.onNav && route.bottomStack).map(MenuListItem)}
          
          <ListItem disablePadding sx={{ color: "primary.main"}}>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ ml: 2 }}>
                <SvgIcon sx={{ fontSize: 31 }} color="primary">
                  <Logout />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={t("logout")}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        </Stack>
      </Drawer>
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {showBackButton && (
              <Box sx={{ ml: 2 }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBack />
                </IconButton>
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Notifications notifications={jobStatuses} />
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Outlet />
        </Box>
      </Box>
      <Box hidden={true} sx={{ position: "absolute", bottom: 16, right: 16 }}>
        <LanguageSelector />
      </Box>
    </Box>
  );
};

export default Navigation;
