import { FC, useState } from "react";
import { Box, Dialog, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { DialogProps, SelectChangeEvent } from "@mui/material";

import { Connection, ConnectionType, DialogMode } from "types";
import FortnoxConnection from "components/FortnoxConnection";

type Props = {
  open: boolean;
  mode: DialogMode;
  clientId: string;
  connection?: Connection;
  handleClose: (reload?: boolean) => void;
};

const ConnectionDialog: FC<Props> = ({
  open,
  mode,
  connection,
  clientId,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [connectionType, setConnectionType] = useState<ConnectionType>(
    connection?.type ?? ConnectionType.Fortnox
  );
  const [loading, setLoading] = useState<boolean>(false);

  const onClose: DialogProps["onClose"] = (event, reason) => {
    if (loading && reason === "backdropClick") return;
    handleClose();
  };

  const onChange = (event: SelectChangeEvent<unknown>) => {
    setConnectionType(event.target.value as ConnectionType);
  };

  return (
    <Dialog {...{ open, onClose }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: 4,
          boxShadow: 24,
          borderRadius: 1,
          width: 800,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {mode === DialogMode.CREATE
            ? t("new_integration")
            : t("existing_integration")}
        </Typography>

        <Typography>
          {mode === DialogMode.CREATE
            ? t("select_connection_type")
            : t("connection_type")}
        </Typography>
        <Select
          disabled={Boolean(connection)}
          style={{ color: "black" }}
          fullWidth
          value={connectionType}
          onChange={onChange}
        >
          <MenuItem value={ConnectionType.Fortnox}>
            {ConnectionType[ConnectionType.Fortnox]}
          </MenuItem>
        </Select>

        <Box mt={4}>
          {connectionType === ConnectionType.Fortnox && (
            <FortnoxConnection
              {...{
                loading,
                setLoading,
                mode,
                connection,
                clientId,
              }}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConnectionDialog;
