import { Box, Button, Stack, Typography } from "@mui/material"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import { withAuthentication } from "auth"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import { getActivationUrl, getTokenExpiryUTC, triggerTokenRefresh } from "api/callbacks";

const Settings = () => {
    const { t } = useTranslation()
    const [valid, setValid] = useState<string | undefined>()
    const [loading, setLoading] = useState(true)

    const getStatusText = () => valid && valid.length > 0 ? 
          t("settings_fabric_connection_validUntil", { until: valid })
        : t("settings_fabric_connection_expired")

    useEffect(() => {
        setLoading(true)
        getTokenExpiryUTC()
            .then(res => {
                if (res && res.status === 200) {
                    setValid(res.data)
                }
            })
            .finally(() => { setLoading(false) })
    }, [])

    const triggerRefresh = () => {
        setLoading(true)
        triggerTokenRefresh()
            .then((res) => {
                if (res && res.status === 200) {
                    setValid(res.data)
                }
            })
            .finally(() => setLoading(false))
    }

    const gotoActivationUrl = () => {
        getActivationUrl()
            .then(res => {
                if (res && res.status === 200) {
                    window.open(res.data.activationUrl)
                }
            })
    }

    return <>
        <Box sx={{ p: 3 }}>
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography 
                    gutterBottom
                    variant="h4"
                >
                    {t("settings_title")}
                </Typography>
            </Stack>

            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">{t("settings_fabric_connection_title")}</Typography>
                    <Typography variant="body2">{t("settings_fabric_connection_status")}: {loading ? t("loading") : getStatusText()}</Typography>
                </CardContent>
                <CardActions>
                    <Button 
                        variant="contained"
                        size="small"
                        onClick={triggerRefresh}
                    >
                        {t("settings_refresh_fabric_token")}
                    </Button> 
                    <Button 
                        variant="contained" 
                        size="small"
                        onClick={gotoActivationUrl}
                    >
                        {t("settings_create_new_fabric_token")}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    </>
}

export default withAuthentication(Settings)