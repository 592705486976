import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  type DialogProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Client, ClientFormValues, DialogMode } from "types";
import { createClient, updateClient } from "api/callbacks";
import { useParams } from "react-router-dom";
import ErrorAlert from "components/ErrorAlert";

type Props = {
  open: boolean;
  handleClose: (reload: boolean) => void;
  initialData?: Client;
  mode: DialogMode;
};

interface AddClientResult {
  success: boolean;
  message: string;
}

const ClientDialog: FC<Props> = ({ open, handleClose, initialData, mode }) => {
  const { t } = useTranslation();
  const { id: organisationId } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addClientResult, setAddClientResult] = useState<
    AddClientResult | undefined
  >();

  const { handleSubmit, control } = useForm<ClientFormValues>({
    defaultValues: {
      name: "",
      email: "",
    },
  });

  useEffect(() => {
    control._reset({
      name: initialData?.name ?? "",
      email: initialData?.email ?? "",
    });
  }, [mode, initialData, control]);

  const onClose: DialogProps["onClose"] = (_event, reason) => {
    if (isLoading && reason && reason === "backdropClick") return;
    handleClose(false);
  };

  const onSubmitHandler = async (client: ClientFormValues) => {
    if (client.name === "" || client.email === "") return;
    setIsLoading(true);
    setAddClientResult(undefined);
    try {
      if (mode === DialogMode.CREATE && organisationId) {
        await createClient({ ...client, organisationId });
      } else if (mode === DialogMode.VIEW && initialData?.id) {
        await updateClient(initialData?.id, client);
      }
      setIsLoading(false);
      setAddClientResult({
        success: true,
        message: "",
      });
      handleClose(true);
    } catch (error) {
      console.error("Error creating or updating client:", error);
      setIsLoading(false);
      setAddClientResult({
        success: false,
        message: `${error}`,
      });
    }
  };

  return (
    <Dialog {...{ open, onClose }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: 4,
          boxShadow: 24,
          borderRadius: 1,
          width: 400,
        }}
      >
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Typography variant="h6" gutterBottom>
            {mode === DialogMode.CREATE
              ? t("add_new_client")
              : t("edit_client")}
          </Typography>
          <Stack spacing={2}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: t("alerts:client_name_required"),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t("client_name")}
                  variant="outlined"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ""}
                  disabled={
                    isLoading || (addClientResult && !addClientResult.success)
                  }
                  onClick={() => {
                    setAddClientResult(undefined);
                  }}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                required: t("alerts:client_email_required"),
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: t("alerts:client_email_invalid"),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t("client_email")}
                  variant="outlined"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ""}
                  disabled={
                    isLoading || (addClientResult && !addClientResult.success)
                  }
                  onClick={() => {
                    setAddClientResult(undefined);
                  }}
                />
              )}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                isLoading || (addClientResult && !addClientResult.success)
              }
            >
              {mode === DialogMode.CREATE ? t("add") : t("save_changes")}
            </Button>
          </Stack>
          <Box
            mt={"10px"}
            display={"flex"}
            justifyContent="center"
            alignItems="center"
          >
            {isLoading && <CircularProgress color="primary" />}
            {addClientResult && addClientResult.success && (
              <CheckIcon color="primary" />
            )}
            {addClientResult && !addClientResult.success && (
              <ErrorAlert title="Error" message={addClientResult.message} />
            )}
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default ClientDialog;
