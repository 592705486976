import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import i18n from "i18n";
import { ComponentColorMode } from "types";

type Props = {
  mode?: ComponentColorMode;
};

const LanguageSelector: FC<Props> = ({ mode = ComponentColorMode.DARK }) => {
  const { t } = useTranslation();

  const handleLanguageChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value);
  };

  const isDark = mode === ComponentColorMode.DARK;

  return (
    <FormControl variant="outlined" size="small">
      <Select
        value={i18n.language}
        onChange={handleLanguageChange}
        sx={{
          ...(isDark && {
            color: "#0a0e16",
            backgroundColor: "#ffffff",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#0a0e16",
            },
            "& .MuiSvgIcon-root": {
              color: "#0a0e16",
            },
          }),
        }}
      >
        <MenuItem value="en">{t("english")}</MenuItem>
        <MenuItem value="sv">{t("swedish")}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
