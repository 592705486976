import React from "react";
import { Alert, AlertTitle } from "@mui/material";

interface ErrorAlertProps {
  title: string;
  message: string;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({
  title = "",
  message = "",
}) => {
  return (
    <Alert
      variant="outlined"
      severity="error"
      sx={{ borderLeftWidth: "4px", bgcolor: "#fae9e5" }}
    >
      <AlertTitle
        sx={{
          display: "inline",
          fontSize: "inherit",
          fontWeight: "bold",
          paddingRight: "2px",
          color: "error.main",
        }}
      >
        {title || "Error"}:
      </AlertTitle>
      {message}
    </Alert>
  );
};

export default ErrorAlert;
