import {Client, ClientFormValues, Connection, SyncType} from "types";
import { axiosInstance } from "utils";

export const fetchClients = async (organisationId: string) => {
  return await axiosInstance.get<Client[]>(`/clients/${organisationId}`);
};

export const createClient = async (client: ClientFormValues) => {
  return await axiosInstance.post<Client>("/clients", client);
};

export const fetchClientConnections = async (clientId: string) => {
  return await axiosInstance.get<Connection[]>(
    `/clients/${clientId}/connections`
  );
};

export const fetchClientConnection = async (
  clientId: string,
  connectionId: string
) => {
  return await axiosInstance.get<Connection>(
    `/clients/${clientId}/connection/${connectionId}`
  );
};

export const updateClient = async (id: string, client: ClientFormValues) => {
  return await axiosInstance.put<Client>(`/clients/${id}`, client);
};

export const deleteClient = async (id: string) => {
  return await axiosInstance.delete(`/clients/${id}`);
};

export const deleteClientConnection = async (
  clientId: string,
  connectionId: string
) => {
  return await axiosInstance.delete(
    `/clients/${clientId}/connection/${connectionId}`
  );
};

export const fortnoxFetchSingle = async (
  clientId: string,
  connectionId: string,
  fetchType: SyncType
) => {
  try {
    await axiosInstance.post<string>(
      `/connection/fortnox/${clientId}/${connectionId}/${fetchType}/fetch`
    );
    return true;
  } catch (error) {
    console.error("Error starting Fortnox fetch:", error);
    return false;
  }
};

export const fortnoxFetchAll = async (
  clientId: string,
  fetchType: SyncType
)=> {
  try {
    await axiosInstance.post<string>(
      `/connection/fortnox/${clientId}/all/${fetchType}/fetch`
    );
    return true;
  } catch (error) {
    console.error("Error starting Fortnox fetch all:", error);
    return false;
  }
};