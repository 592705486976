import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { format, parseISO } from "date-fns";
import { Loop } from "@mui/icons-material";

import {
  Connection,
  ConnectionType,
  DialogMode,
  FortnoxCompanyInformation,
  ObjectType,
} from "types";
import { deleteClientConnection, fetchClientConnections } from "api/callbacks";
import DeleteButton from "components/Buttons/DeleteButton";
import ConnectionDialog from "components/Dialogs/ConnectionDialog";
import ConnectionHandler from "components/Buttons/ConnectionButton";
import { withAuthentication } from "auth";
import SyncConnectionsDataDialog from "components/Dialogs/SyncConnectionsDataDialog";

const OrganisationClientConnections = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id, clientId } = useParams<{ id: string; clientId: string }>();
  const [connections, setConnections] = useState<Connection[]>([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<DialogMode>(DialogMode.VIEW);
  const [connection, setConnection] = useState<Connection | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchDialogOpen, setFetchDialogOpen] = useState(false);

  const loadConnections = useCallback(async () => {
    setIsLoading(true);
    if (clientId) {
      try {
        const { data: fetchedConnections } = await fetchClientConnections(
          clientId
        );
        setConnections(fetchedConnections);
      } catch (error) {
        console.error("Error fetching connections:", error);
        enqueueSnackbar(t("error_loading_connections"), { variant: "error" });
      }
    }
    setIsLoading(false);
  }, [clientId, enqueueSnackbar, t]);

  useEffect(() => {
    loadConnections();
  }, [id, loadConnections]);

  const handleOpen = (mode: DialogMode, connection?: Connection) => {
    setMode(mode);
    setConnection(connection);
    setOpen(true);
  };

  const handleClose = (reload?: boolean) => {
    setOpen(false);
    if (reload) {
      loadConnections();
      enqueueSnackbar(t("integration_success"), { variant: "success" });
    }
  };
  
  const handleFetchOpen = () => {
    setFetchDialogOpen(true);
  }
  
  const handleFetchClose = () => {
    setFetchDialogOpen(false);
  }

  const handleDelete = async (connection: Connection) => {
    setIsLoading(true);
    try {
      if (clientId) {
        await deleteClientConnection(clientId, connection.id);
        await loadConnections();
        enqueueSnackbar(t("connection_deleted_success"), {
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error deleting connection:", error);
      enqueueSnackbar(t("error_deleting_connection"), { variant: "error" });
    }
    setIsLoading(false);
  };

  const extractCompanyInformation = (metadata?: { [key: string]: string }) => {
    const companyInfo: FortnoxCompanyInformation | undefined =
      metadata?.companyInformation
        ? JSON.parse(metadata.companyInformation)
        : undefined;

    const companyName = companyInfo?.CompanyName;
    const organisationNumber = companyInfo?.OrganizationNumber;

    return (
      <>
        <TableCell>{companyName}</TableCell>
        <TableCell>{organisationNumber}</TableCell>
      </>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <Typography variant="h4" gutterBottom>
          {t("client_connections", { id })}
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen(DialogMode.CREATE)}
            disabled={isLoading}
          >
            {t("new_integration")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{ ml: 2 }}
            onClick={handleFetchOpen}
          >
            {t("sync_data")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => loadConnections()}
            disabled={isLoading}
            sx={{ ml: 2 }}
          >
            <Loop />
          </Button>
        </Box>
      </Stack>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("id")}</TableCell>
              <TableCell>{t("integration")}</TableCell>
              <TableCell>{t("company_name")}</TableCell>
              <TableCell>{t("organisation_number")}</TableCell>
              <TableCell>{t("selected_scopes")}</TableCell>
              <TableCell>{t("last_sync")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {connections?.map((connection) => (
              <TableRow key={connection.id}>
                <TableCell>{connection.id}</TableCell>
                <TableCell>{ConnectionType[connection.type]}</TableCell>
                {extractCompanyInformation(connection.metadata)}
                <TableCell>
                  {clientId && (
                    <ConnectionHandler
                      {...{
                        clientId,
                        connection,
                        handleOpen: () =>
                          handleOpen(DialogMode.VIEW, connection),
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {connection.triggeredTimestampUtc &&
                    format(
                      parseISO(connection.triggeredTimestampUtc),
                      "yyyy-MM-dd HH:mm:ss"
                    )}
                </TableCell>{" "}
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 1,
                      bgcolor: "background.paper",
                      color: "text.secondary",
                      cursor: "pointer",
                      width: "fit-content",
                      "& svg": { m: 2 },
                    }}
                  >
                    <DeleteButton
                      onDelete={() => handleDelete(connection)}
                      itemName={connection.id}
                      itemType={ObjectType.CONNECTION}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        mt={"2em"}
        display={"flex"}
        justifyContent="center"
        alignItems="center"
      >
        {isLoading && <CircularProgress color="primary" />}
      </Box>

      {open && clientId && (
        <ConnectionDialog
          {...{ clientId, mode, open, connection, handleClose }}
        />
      )}
      {fetchDialogOpen && clientId &&(
        <SyncConnectionsDataDialog open={fetchDialogOpen} handleClose={handleFetchClose} clientId={clientId}></SyncConnectionsDataDialog>
      )}
    </Box>
  );
};

export default withAuthentication(OrganisationClientConnections);
