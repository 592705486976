import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { format as formatDate, formatDistanceToNow, Locale } from "date-fns";
import { enUS, sv } from "date-fns/locale";

import * as resources from "assets/locales";

const debug = process.env.NODE_ENV !== "production";
const supportedLngs = ["en", "sv"];
const defaultFnsLocale: { [key: string]: Locale } = { en: enUS, sv: sv };

void i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  ns: ["common, scopes, alerts"],
  fallbackLng: "en",
  debug,
  supportedLngs,
  load: "currentOnly",
  defaultNS: "common",
  detection: {
    order: [
      "querystring",
      "cookie",
      "localStorage",
      "navigator",
      "htmlTag",
      "path",
      "subdomain",
    ],
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: ["localStorage", "cookie"],
    excludeCacheFor: ["cimode"],
    cookieMinutes: 10,
    cookieDomain: "myDomain",
    htmlTag: document.documentElement,
  },
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      const locale = defaultFnsLocale[lng ?? "en"];
      if (format === "uppercase" && typeof value === "string")
        return value.toUpperCase();
      if (format === "date" && value instanceof Date)
        return formatDate(value, "PP", { locale });
      if (format === "time" && value instanceof Date)
        return formatDate(value, "p", { locale });
      if (format === "dateTime" && value instanceof Date)
        return formatDate(value, "Pp", { locale });
      if (format === "dateTimeWithSeconds" && value instanceof Date)
        return formatDate(value, "MMM do, HH:mm:ss", { locale });
      if (format === "relativeTime" && value instanceof Date)
        return formatDistanceToNow(value, { locale });
      return String(value);
    },
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
