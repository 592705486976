import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Edit as EditIcon } from "@mui/icons-material";

import OrganisationDialog from "components/Dialogs/OrganisationDialog";
import { DialogMode, ObjectType, Organisation } from "types";
import {
  deleteOrganisation,
  fetchOrganisations,
} from "api/callbacks/organisations";
import DeleteButton from "components/Buttons/DeleteButton";
import { withAuthentication } from "auth";

const Organisations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<DialogMode>(DialogMode.CREATE);
  const [currentOrganisation, setCurrentOrganisation] = useState<
    Organisation | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);

  const loadOrganisations = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchOrganisations();
      setOrganisations(response.data);
    } catch (error) {
      console.error("Failed to fetch organisations", error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadOrganisations();
  }, [loadOrganisations]);

  const handleOpen = (mode: DialogMode, organisation?: Organisation) => {
    setMode(mode);
    setCurrentOrganisation(organisation);
    setOpen(true);
  };

  const handleClose = (reload?: boolean) => {
    setOpen(false);
    if (reload) {
      loadOrganisations();
    }
  };

  const handleDelete = async (organisation: Organisation) => {
    setIsLoading(true);
    try {
      await deleteOrganisation(organisation.id);
      setOrganisations((prev) =>
        prev.filter((org) => org.id !== organisation.id)
      );
    } catch (error) {
      console.error("Failed to delete organisation", error);
    }
    setIsLoading(false);
  };

  const handleNavigate = (id: string) => {
    navigate(`/organisations/${id}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <Typography variant="h4" gutterBottom>
          {t("organisations")}
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(DialogMode.CREATE)}
          disabled={isLoading}
        >
          {t("add_org")}
        </Button>
      </Stack>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>{t("id")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("name")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("clients")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organisations?.map((organisation) => (
              <TableRow
                key={organisation.id}
                hover
                sx={{ cursor: "pointer", boxShadow: "2" }}
                onClick={() => handleNavigate(organisation.id)}
              >
                <TableCell>{organisation.id}</TableCell>
                <TableCell>{organisation.name}</TableCell>
                <TableCell>{organisation.clientNr}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 1,
                      bgcolor: "background.paper",
                      color: "text.secondary",
                      cursor: "pointer",
                      width: "fit-content",
                      zIndex: 10,
                      position: "relative",
                      "& svg": {
                        m: 2,
                      },
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpen(DialogMode.VIEW, organisation);
                      }}
                      sx={{ m: 0, p: 0, "&:hover": { color: "black" } }}
                      style={{ borderRadius: 0 }}
                    >
                      <EditIcon />
                    </IconButton>

                    <Divider orientation="vertical" flexItem />

                    <DeleteButton
                      onDelete={() => handleDelete(organisation)}
                      itemName={organisation.name}
                      itemType={ObjectType.ORGANISATION}
                      disabled={organisation.clientNr > 0}
                      disabledReason={t("delete_org_disabled_reason")}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        mt={"2em"}
        display={"flex"}
        justifyContent="center"
        alignItems="center"
      >
        {isLoading && <CircularProgress color="primary" />}
      </Box>

      <OrganisationDialog
        open={open}
        handleClose={handleClose}
        initialData={currentOrganisation}
        mode={mode}
      />
    </Box>
  );
};

export default withAuthentication(Organisations);
