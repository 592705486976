import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Edit as EditButton } from "@mui/icons-material";
import { OpenInNew } from "@mui/icons-material/";
import { format, compareDesc, parseISO } from "date-fns";

import ClientDialog from "components/Dialogs/ClientDialog";
import { Client, DialogMode, ObjectType } from "types";
import { deleteClient, fetchClients } from "api/callbacks";
import DeleteButton from "components/Buttons/DeleteButton";
import { withAuthentication } from "auth";

const OrganisationClients = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [clients, setClients] = useState<Client[]>([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<DialogMode>(DialogMode.CREATE);
  const [currentClient, setCurrentClient] = useState<Client | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const loadClients = useCallback(async () => {
    setIsLoading(true);
    if (id) {
      try {
        const { data: fetchedClients } = await fetchClients(id);
        setClients(fetchedClients);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    loadClients();
  }, [id, loadClients]);

  const handleOpen = (mode: DialogMode, client?: Client) => {
    setMode(mode);
    setCurrentClient(client);
    setOpen(true);
  };

  const handleClose = (reload?: boolean) => {
    setOpen(false);
    if (reload) {
      loadClients();
    }
  };

  const handleDelete = async (client: Client) => {
    setIsLoading(true);
    try {
      await deleteClient(client.id);
      await loadClients();
    } catch (error) {
      console.error("Error deleting client:", error);
    }
    setIsLoading(false);
  };

  const handleNavigate = (clientId: string) => {
    navigate(`/organisations/${id}/client/${clientId}`);
  };

  const getLatestSync = (client: Client): string => {
    const syncs = client.connections
      .filter((x) => x.triggeredTimestampUtc)
      .map((connection) => parseISO(connection.triggeredTimestampUtc));

    return syncs.length === 0
      ? ""
      : format(
          syncs.sort((a, b) => compareDesc(a, b))[0],
          "yyyy-MM-dd HH:mm:ss"
        );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <Typography variant="h4" gutterBottom>
          {t("organisation_clients", { id })}
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(DialogMode.CREATE)}
          disabled={isLoading}
        >
          {t("add_client")}
        </Button>
      </Stack>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("id")}</TableCell>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("email")}</TableCell>
              <TableCell>{t("fabric_workspace")}</TableCell>
              <TableCell>{t("connections")}</TableCell>
              <TableCell>{t("last_sync")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients?.map((client) => (
              <TableRow
                key={client.id}
                hover
                sx={{ cursor: "pointer" }}
                onClick={() => handleNavigate(client.id)}
              >
                <TableCell>{client.id}</TableCell>
                <TableCell>{client.name}</TableCell>
                <TableCell>{client.email}</TableCell>
                <TableCell>
                  {client.resourcesInfo && (
                    <Link
                      sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                      target="_blank"
                      href={`https://app.fabric.microsoft.com/groups/${client.resourcesInfo.workspaceId}/list?experience=data-factory`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {client.resourcesInfo.workspaceName}
                      <OpenInNew fontSize="inherit" />
                    </Link>
                  )}
                </TableCell>
                <TableCell>{client.connections.length}</TableCell>
                <TableCell>{getLatestSync(client)}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 1,
                      bgcolor: "background.paper",
                      color: "text.secondary",
                      cursor: "pointer",
                      width: "fit-content",
                      "& svg": {
                        m: 2,
                      },
                    }}
                  >
                    <EditButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpen(DialogMode.VIEW, client);
                      }}
                    />
                    <Divider orientation="vertical" flexItem />
                    <DeleteButton
                      onDelete={() => handleDelete(client)}
                      itemName={client.name}
                      itemType={ObjectType.CLIENT}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        mt={"2em"}
        display={"flex"}
        justifyContent="center"
        alignItems="center"
      >
        {isLoading && <CircularProgress color="primary" />}
      </Box>

      {open && (
        <ClientDialog
          {...{ open, handleClose, initialData: currentClient, mode }}
        />
      )}
    </Box>
  );
};

export default withAuthentication(OrganisationClients);
